<template>
    <button type="button" class="px-8 py-3  border rounded border-violet-600 text-violet-600 mb-5 mt-20" @click="handleCheck">Verify</button>
    <div class="w-full flex justify-center ">
	<fieldset class="space-y-1 text-gray-100 p-4 rounded-md">
		<label for="Search" class="hidden">Search</label>
		<div class="relative mb-8">
			<span class="absolute inset-y-0 right-0 flex items-center pr-2">
				<button type="button" title="search" class="p-1 focus:outline-none focus:ring" @click="handleCheck">
					<svg fill="currentColor" viewBox="0 0 512 512" class="w-4 h-4 text-gray-200">
						<path d="M479.6,399.716l-81.084-81.084-62.368-25.767A175.014,175.014,0,0,0,368,192c0-97.047-78.953-176-176-176S16,94.953,16,192,94.953,368,192,368a175.034,175.034,0,0,0,101.619-32.377l25.7,62.2L400.4,478.911a56,56,0,1,0,79.2-79.195ZM48,192c0-79.4,64.6-144,144-144s144,64.6,144,144S271.4,336,192,336,48,271.4,48,192ZM456.971,456.284a24.028,24.028,0,0,1-33.942,0l-76.572-76.572-23.894-57.835L380.4,345.771l76.573,76.572A24.028,24.028,0,0,1,456.971,456.284Z"></path>
					</svg>
				</button>
			</span>
			<input 
                type="search" 
                name="Search" 
                placeholder="Please enter a FB Taproot type address to check whitelist." 
                class="w-[560px] py-2 pr-6 text-sm rounded-md focus:outline-none focus:bg-gray-900 placeholder-gray-500 text-gray-300 focus:border-violet-600 bg-gray-700 text-center"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
                data-ms-editor="false"
                @keyup.enter="handleCheck"
                v-model="searchInput"
            >
		</div>
	</fieldset>
    </div>
    <div v-if="isShowSuccess" class="w-full flex flex-col justify-center items-center text-green-500">

        <div class="mb-8">
            <span class="text-green-600">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-12"
            >
                <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
                </svg>
            </span>
        </div>
        <h4 class="mt-[-20px]">Verification Successful.</h4>
    </div>
    <div v-if="isShowFailed" class="w-full flex justify-center text-gray-400">
        <h4> 😭 Verification Failure. You can participate in the public mint.</h4>
    </div>
    <div v-if="isAddressError" class="w-full flex justify-center text-amber-400">
        <h4>🙅 Address Error! Please enter a valid Taproot type address, such as b1cp...</h4>
    </div>
</template>
<script setup>
import {check} from './utils'
import * as Api from '@/api/server';

</script>

<script>
export default {
	name: 'Check',
    data() {
        return {
            searchInput: '',
            isShowSuccess: false, // 新增 isShowSuccess 变量
            isShowFailed: false, // 新增 isShowFailed 变量
            isAddressError: false, // 新增 isAddressError 变量
        }
    },
    watch: {
        searchInput: {
            handler: 'handleTextChange',
            immediate: false
        }
    },
    methods: {
        handleTextChange() {
            // 在这里实现当 searchInput 变化时需要执行的逻辑
            console.log('searchInput changed:', this.searchInput);
            this.isAddressError = false; // 设置 isAddressError 为 false
            this.isShowSuccess = false; // 设置 isShowSuccess 为 false
            this.isShowFailed = false; // 设置 isShowFailed 为 false
              },
        async handleCheck() {

            let isCheck = await check(this.searchInput);
            if(isCheck) {
                this.isAddressError = false; // 设置 isAddressError 为 false
                try{
                    let res = await Api.whiteListValidate(this.searchInput);
                    console.log('res---',res);
                    if (res.isValid) {
                        console.log('check success---');
                        this.isShowSuccess = true; // 设置 isWhiteList 为 true
                        this.isShowFailed = false; // 设置 isShowFailed 为 true
                    }
                    else {
                        console.log('whitelist failed---');
                        this.isShowSuccess = false; // 设置 isWhiteList 为 false
                        this.isShowFailed = true; // 设置 isShowFailed 为 true
                    }
                }
                catch(e){
                    console.log('Api whiteListValidate error---',e);
                }
            }
            else {
                console.log('address type error---');
                this.isAddressError = true; // 设置 isAddressError 为 true
            }
        },
        getAddressFromUrl() {
            const urlParams = new URLSearchParams(window.location.search);
            const address = urlParams.get('address');
            if (address) {
                this.searchInput = address;
                this.handleCheck(); // 可选：自动执行检查
            }
        }
    },
    mounted() {
        this.getAddressFromUrl();
    }
};
</script>

<style scoped>
/* 禁用浏览器默认的自动填充样式 */
input[type="search"]:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

@-webkit-keyframes autofill {
    to {
        color: var(--input-text-color);
        background: var(--input-bg-color);
    }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
</style>