import { ref } from 'vue';
import * as Api from '@/api/server';
import * as MempoolApi from '@/api/mempool';

export const isDebug = false;

export const sendFail = ref(false);
export const feeRate = ref(30); // 默认值设为30
export const hSeed = ref('dBxo0jBAYVAGP3yb'); // 默认值设为30
export const price = ref(0.0008);
export const mintFail = ref(false);
export const baseSeedUrl = `https://t.bastudio.xyz/app/seed/seedHTML`;

export const isShowError = ref(false);
export const isWalletNotInstalled = ref(false);

const feeRateMap = ref({
    Slow: 0,
    Normal: 0,
    Fast: 0,
  });

export function changeRadio(val) {
    feeRate.value = feeRateMap.value[val];
}

export function handleAccountsChanged(accounts) {
  console.log('handleAccountsChanged:', accounts);
  address.value = accounts[0];
  check();
}

export function checkWalletInstalled() {

  if (typeof window.unisat !== 'undefined') {
    console.log('UniSat Wallet is installed!');
    isWalletNotInstalled.value = false ;
  } else {
    isWalletNotInstalled.value = true;
  }
}

export async function check(address) {

  let isTaproot = isValidTaprootAddress(address);
  // console.log('isTaproot:',isTaproot);
  return isTaproot ;
  // if (!address.value.startsWith('bc1p')) {
  //   address.value = '';
  //   console.log('check error---');
  //   return false;
  // }

  // return true;
}

export async function reGenerate() {
  try {
    const randomUsableSeedRes = await Api.randomUsableSeed(address.value);
    hSeed.value = randomUsableSeedRes.hSeed;

    sendFail.value = false;

  } catch (e) {
    console.error(e);
  }
}
export async function updateFeeRate() {
  try {
    const data = await MempoolApi.getFeeRate();
    feeRate.value = Math.ceil(data.fastestFee);
    feeRateMap.value = {
      Slow: Math.ceil(data.minimumFee),
      Normal: Math.ceil(data.halfHourFee),
      Fast: Math.ceil(data.fastestFee),
    };
    changeRadio('Normal');
    console.log('Fee rate updated:', feeRate.value);
  } catch (error) {
    console.error('更新费率时出错:', error);
  }
}

export function isValidTaprootAddress( address ) {
  try {
      // console.log(BTON);
      BTON.Tap.decodeAddress( address ).hex;
      // console.log('isOK');
      return true;
  } catch( e ) {
      console.log('isBad');
  }
  return false ;
}

// 定时器函数
export function startFeeRateUpdateTimer() {
  // 立即执行一次
  updateFeeRate();
  
  // 每3分钟执行一次
  setInterval(updateFeeRate, 3 * 60 * 1000);
}
